var _a, _b, _c, _d;
import { StoreDownload } from '@introcloud/blocks';
import Constants from 'expo-constants';
import { createElement } from 'react';
import { useIsMobileView } from '../../utils/useIsMobileView';
import { useTranslation } from '../../utils/useTranslation';
const ANDROID_URL = (_b = (_a = Constants.manifest) === null || _a === void 0 ? void 0 : _a.extra) === null || _b === void 0 ? void 0 : _b.playStoreUrl;
const IOS_URL = (_d = (_c = Constants.manifest) === null || _c === void 0 ? void 0 : _c.extra) === null || _d === void 0 ? void 0 : _d.appStoreUrl;
export function StoreLinksBlock({ value: { label, localizedLabel, androidUrlOverride, iosUrlOverride }, }) {
    const androidUrl = androidUrlOverride || ANDROID_URL;
    const iosUrl = iosUrlOverride || IOS_URL;
    const isMobileView = useIsMobileView();
    const title = useTranslation(localizedLabel, label);
    return createElement(StoreDownload, {
        iosUrl,
        androidUrl,
        isMobileView,
        title,
    });
}
