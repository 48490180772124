import { useBlockData } from '@introcloud/blocks-interface';
import { createElement } from 'react';
import { useErrorHandler } from '../ErrorHandler';
import { usePageData } from '../PageData';
import { CheckInBlock } from './CheckInBlock';
import { DirectionsBlock } from './DirectionsBlock';
import { EventsBlock } from './EventsBlock';
import { ImageBlock } from './ImageBlock';
import { LocationsBlock } from './LocationsBlock';
import { TextBlock } from './TextBlock';
import { TimeSlotsBlock } from './TimeSlotsBlock';
import { TimeSpanBlock } from './TimeSpanBlock';
export function AutoBlock(block) {
    switch (block.value.auto) {
        case 'title': {
            return createElement(AutoTitleBlock, block);
        }
        case 'description': {
            return createElement(AutoDescriptionBlock, block);
        }
        case 'directions': {
            return createElement(AutoDirectionsBlock, block);
        }
        case 'image': {
            return createElement(AutoImageBlock, block);
        }
        case 'timespan': {
            return createElement(AutoTimeSpanBlock, block);
        }
        case 'locations': {
            return createElement(AutoLocationsBlock, block);
        }
        case 'sub-events': {
            return createElement(AutoSubEventsBlock, block);
        }
        case 'upcoming-events': {
            return createElement(AutoUpcomingEventsBlock, block);
        }
        case 'self-check-in': {
            return createElement(AutoSelfCheckInBlock, block);
        }
        case 'timeslots': {
            return createElement(AutoTimeslotsBlock, block);
        }
        default: {
            throw new Error(`Unknown auto type: ${block.value.auto}`);
        }
    }
}
export function AutoTitleBlock(block) {
    var _a;
    const { renderEmpty, handleEmpty } = useErrorHandler();
    const { pageEvent, pageLocation } = usePageData();
    if (!pageEvent && !pageLocation) {
        return handleEmpty ? renderEmpty(block) : null;
    }
    const text = (pageEvent === null || pageEvent === void 0 ? void 0 : pageEvent.name.full) || (pageLocation === null || pageLocation === void 0 ? void 0 : pageLocation.name.full);
    if (!text) {
        return handleEmpty ? renderEmpty(block) : null;
    }
    const round = ((_a = block.options) === null || _a === void 0 ? void 0 : _a.round) || undefined;
    const options = Object.assign(Object.assign({}, block), { kind: 'text', value: { text }, options: {
            size: 'headline',
            round,
        } });
    return createElement(TextBlock, options);
}
export function AutoDescriptionBlock(block) {
    var _a;
    const { renderEmpty, handleEmpty } = useErrorHandler();
    const { pageEvent, pageLocation } = usePageData();
    if (!pageEvent && !pageLocation) {
        return handleEmpty ? renderEmpty(block) : null;
    }
    const text = (pageEvent === null || pageEvent === void 0 ? void 0 : pageEvent.name.description) || (pageLocation === null || pageLocation === void 0 ? void 0 : pageLocation.name.description);
    if (!text) {
        return handleEmpty ? renderEmpty(block) : null;
    }
    const round = ((_a = block.options) === null || _a === void 0 ? void 0 : _a.round) || undefined;
    const options = Object.assign(Object.assign({}, block), { kind: 'text', value: { text }, options: {
            size: 'paragraph',
            round,
        } });
    return createElement(TextBlock, options);
}
export function AutoDirectionsBlock(block) {
    const { renderEmpty, handleEmpty } = useErrorHandler();
    const { pageLocation } = usePageData();
    if (!pageLocation) {
        return handleEmpty ? renderEmpty(block) : null;
    }
    if (!pageLocation.street &&
        (!pageLocation.geojson ||
            !pageLocation.geojson.coordinates ||
            !pageLocation.geojson.coordinates[0])) {
        return handleEmpty ? renderEmpty(block) : null;
    }
    const { street, addition, city, country, number, postal } = pageLocation;
    const location = {
        street,
        addition,
        city,
        country,
        number,
        postal,
    };
    const coordinates = pageLocation.geojson && pageLocation.geojson.type === 'Point'
        ? pageLocation.geojson.coordinates
        : undefined;
    const { value: { icon, label, localizedLabel }, } = block;
    const options = Object.assign(Object.assign({}, block), { kind: 'directions', value: {
            icon,
            label,
            localizedLabel,
            location,
            coordinates,
            title: pageLocation.name.full,
        }, options: block.options });
    return createElement(DirectionsBlock, options);
}
export function AutoImageBlock(block) {
    var _a, _b, _c;
    const { renderEmpty, handleEmpty } = useErrorHandler();
    const { pageEvent, pageLocation } = usePageData();
    const { getImageUrl } = useBlockData();
    if (!pageEvent && !pageLocation) {
        return handleEmpty ? renderEmpty(block) : null;
    }
    const imageId = ((_a = pageEvent === null || pageEvent === void 0 ? void 0 : pageEvent.image) === null || _a === void 0 ? void 0 : _a.profile) ||
        ((_b = pageEvent === null || pageEvent === void 0 ? void 0 : pageEvent.image) === null || _b === void 0 ? void 0 : _b.banner) ||
        ((_c = pageLocation === null || pageLocation === void 0 ? void 0 : pageLocation.image) === null || _c === void 0 ? void 0 : _c.profile);
    const url = imageId && getImageUrl(imageId, 'icon_1440');
    if (!url) {
        return handleEmpty ? renderEmpty(block) : null;
    }
    const options = Object.assign(Object.assign({}, block), { kind: 'image', value: { src: url }, options: block.options });
    return createElement(ImageBlock, options);
}
export function AutoTimeSpanBlock(block) {
    const options = Object.assign(Object.assign({}, block), { kind: 'timespan', value: {}, options: {
            mode: 'event',
        } });
    return createElement(TimeSpanBlock, options);
}
export function AutoLocationsBlock(block) {
    var _a, _b;
    const round = ((_a = block.options) === null || _a === void 0 ? void 0 : _a.round) || undefined;
    const big = ((_b = block.options) === null || _b === void 0 ? void 0 : _b.big) || false;
    const options = Object.assign(Object.assign({}, block), { kind: 'locations', value: Object.assign({ ids: [] }, block.value), options: {
            mode: 'event',
            round,
            big,
        } });
    return createElement(LocationsBlock, options);
}
export function AutoSubEventsBlock(block) {
    var _a;
    const round = ((_a = block.options) === null || _a === void 0 ? void 0 : _a.round) || undefined;
    const options = Object.assign(Object.assign({}, block), { kind: 'events', value: Object.assign({ ids: [] }, block.value), options: {
            mode: 'sub-events',
            round,
        } });
    return createElement(EventsBlock, options);
}
export function AutoUpcomingEventsBlock(block) {
    var _a;
    const round = ((_a = block.options) === null || _a === void 0 ? void 0 : _a.round) || undefined;
    const options = Object.assign(Object.assign({}, block), { kind: 'events', value: Object.assign({ ids: [] }, block.value), options: {
            mode: 'location',
            round,
        } });
    return createElement(EventsBlock, options);
}
export function AutoSelfCheckInBlock(block) {
    const options = Object.assign(Object.assign({}, block), { kind: 'check-in', value: {
            event: 'auto',
            label: block.value.label,
            localizedLabel: block.value.localizedLabel,
        } });
    return createElement(CheckInBlock, options);
}
export function AutoTimeslotsBlock(block) {
    var _a;
    const round = ((_a = block.options) === null || _a === void 0 ? void 0 : _a.round) || undefined;
    const options = Object.assign(Object.assign({}, block), { kind: 'timeslots', value: {
            event: 'auto',
            label: block.value.label,
            localizedLabel: block.value.localizedLabel,
        }, options: {
            round,
            unregisterable: block.options.unregisterable,
        } });
    return createElement(TimeSlotsBlock, options);
}
